@font-face {
	font-family: 'Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Cairo Regular'), url('fonts/Cairo-Regular.woff') format('woff');
}


@font-face {
	font-family: 'ExtraLight';
	font-style: normal;
	font-weight: normal;
	src: local('Cairo ExtraLight'), url('fonts/Cairo-ExtraLight.woff') format('woff');
}
 

@font-face {
	font-family: 'Light';
	font-style: normal;
	font-weight: normal;
	src: local('Cairo Light'), url('fonts/Cairo-Light.woff') format('woff');
}


@font-face {
	font-family: 'Bold';
	font-style: normal;
	font-weight: normal;
	src: local('Cairo SemiBold'), url('fonts/Cairo-SemiBold.woff') format('woff');
}


@font-face {
	font-family: 'ExtraBold';
	font-style: normal;
	font-weight: normal;
	src: local('Cairo Bold'), url('fonts/Cairo-Bold.woff') format('woff');
}


@font-face {
	font-family: 'Black';
	font-style: normal;
	font-weight: normal;
	src: local('Cairo Black'), url('fonts/Cairo-Black.woff') format('woff');
}

body {
	background-color: #ffffff; /* f4f5f9 */
	margin: 0;
	font-family: 'Regular', BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #6d6ca5 !important;

	&.bp3-dark {
		background-color: #7b8288;
	}
}

body, html, #root, #portal {
	height: 100%;
}

img {
	max-width: 100%;
}

a:hover {
	text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	font-family: ExtraBold, sans-serif;
}

#portal {
	padding-top: 95px;

	@media all and (max-width: 768px) {
		padding-top: 60px;
	}
}

#navbar
{
	padding: 10px 0;
	margin: 0;
	height: 92px;
	box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	#logo {
		border-radius: 5px;
		width: 250px;
		height: 100%;
		margin: 20px 10px 0;
		float: right;
		text-align: right;

		img {
			max-height: 100%;
		}

		@media all and (max-width: 768px) {
			margin: 0;
		}
	}

	#beta {
		margin-left: 100px;
		background-color: #efb932;
		padding: 15px 20px;
		border-radius: 30px;
		color: #423c16;
	}

	#branch-selector {
		margin-top: 15px;
	}

	#nav-time
	{
		text-align: center;
		font-size: 18px;
		padding: 6px 15px;
		border-right: 1px solid #ddd;
		margin-top: 12px;
		margin-right: 20px;
		display: inline-block;

		h5 {
			margin: 0;
			font-size: 15px;
		}
	}

	#global-search
	{
		flex-grow: 1;
		margin: 7px 20px 0;

		input
		{
			background-color: #bdbed3;
			border-radius: 30px;
			padding: 10px 15px 10px 55px;
			color: #585862;
			height: auto;
			box-shadow: none;
			transition: background-color 0.5s, border 0.5s;
			white-space: nowrap;
			text-overflow: ellipsis;

			&::-webkit-input-placeholder {
				color: #f6f6f6;
			}

			&:focus, &:hover {
				background-color: #cecfe6;
				box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
			}

			&:focus::-webkit-input-placeholder {
				color: transparent;
			}
		}

		span.bp3-icon {
			margin: 17px 15px;
			color: #ffffff;
		}

		.bp3-button {
			margin: 13px 8px;
			color: #ffffff;

			&:before {
				font-size: 25px;
				margin-top: 0;
				border-right: 1px solid #ffffff;
				padding: 0 10px 5px 0;
			}
		}

		.bp3-navbar-divider {
			height: 50px;
		}
	}

	.bp3-navbar-group {
		height: auto;
		float: right;
	}

	.notifications
	{
		vertical-align: top;
		margin-top: 13px;
		padding: 10px 15px;
		display: inline-block;
		cursor: pointer;
		border: 1px solid #bdbed3;
		border-top: 0;
		border-bottom: 0;
		position: relative;

		.badge {
			position: absolute;
			top: 10px;
			right: 10px;
			background: #21bdca;
			color: #ffffff;
			padding: 2px 4px 4px;
			border-radius: 50%;
			font-size: 13px;
			font-family: "Bold", serif;
		}

		.bp3-icon {
			color: #bdbed3;
		}
	}

	.bp3-navbar-divider {
		margin: 10px;
		height: 50px;
		display: inline-block;
	}

	#user-menu .bp3-popover-target > div {
		margin-right: 10px;
		display: flex;
	}

	@media print {
		display: none;
	}
}

#wrapper {
	display: flex;
	min-height: 100%;


	@media all and (max-width: 768px) {
		#wrapper {
			flex-flow: column;
		}
	}
}

#sidebar
{
	background-color: #2a47c5;
	width: 66px;
	box-shadow: 0 0 9px rgba(0, 0, 0, 0.01);
	flex-shrink: 0;
	z-index: 10;
	position: relative;
	transition: 0.2s right, 0.2s opacity,  width 0.3s;

	#apps-list
	{
		width: 66px;
		height: 100%;
		float: left;
		margin: 0;
		padding: 15px 0 0;
		list-style: none;
		max-width: none;

		> li {
			text-align: center;
			color: #ffffff;
			cursor: pointer;
			transition: color 0.4s;
			z-index: 1;

			&:hover > a {
				background-color: rgba(255, 255, 255, 0.9);
				color: #21bdca;
			}

			&.show-submenu .submenu {
				display: block;
			}

			> a {
				padding: 15px;
				color: #ffffff;
				display: block;
				font-size: 14px;
				border-radius: 6px 0 0 6px;

				@media screen and (max-width: 768px), screen and (max-height: 640px) {
					padding: 8px;
				}

				.name {
					display: none;
				}
			}

			&.active > a {
				background-color: #fff;
				content: "";
				display: block;
				color: #21bdca;
			}
		}

		.submenu
		{
			background-color: #fff;
			min-width: 230px;
			padding: 30px;
			position: absolute;
			top: 0;
			right: 66px;
			height: 100%;
			overflow: auto;
			display: none;
			box-shadow: -4px 2px 6px rgba(0, 0, 0, 0.1);

			h3 {
				color: #d2810a;
				font-size: 13px;
				text-align: right;
			}

			.level-1 > h3 {
				color: #21bdca;
				font-size: 17px;
				margin-top: 11px;
			}

			ul
			{
				padding: 0;
				margin: 0;
				list-style: none;

				a
				{
					padding: 6px 5px 8px;
					margin-bottom: 1px;
					color: #606077;
					font-size: 13px;
					display: block;
					transition: background-color 0.5s;
					text-align: right;

					&:hover {
						background-color: #d7d7d7;
					}

					&.active
					{
						background-color: #21bdca;
						color: #ffffff;
						width: 103%;
						padding-left: 25px;

						.bp3-icon {
							float: right;
							transform: scale(1.5);
							margin-top: 3px;
						}
					}

					.bp3-icon {
						vertical-align: middle;
					}
				}
			}
		}
	}

	@media all and (max-width: 768px) {
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		right: -100%;
		z-index: 10;
		opacity: 0;

		&.show-mobile-sidebar {
			right: 0;
			opacity: 1;
		}

		#apps-list li.active .submenu {
			display: block;
		}

		.user-menu {
			position: fixed;
			bottom: 5px;
			right: 3px;
			text-align: center;

			h3, h5 {
				display: none;
			}
		}

		.close {
			margin: 10px;
			color: #ffffff;
			float: left;
			display: block;
		}
	}

	@media print {
		display: none;
	}
}

#wrapper.horizontal-sidebar
{
	display: block;

	#sidebar
	{
		width: auto;

		#apps-list
		{
			width: auto;
			float: none;
			text-align: center;
			padding: 0;
			margin: 0 auto;
			position: relative;
			display: flex;

			li
			{
				display: inline-block;
				flex-grow: 1;

				a {
					border-radius: 0;
					margin: 0;
					font-size: 11px;
					padding: 15px !important;

					.name {
						display: block;
					}

					i {
						margin: 0;
					}
				}

				.submenu
				{
					width: 100%;
					top: 100%;
					right: 0;
					height: auto;
					overflow: visible;
					box-shadow: 0 20px 50px rgba(0,0,0,.4);

					a {
						padding: 6px 5px 8px !important;
						font-size: 13px;

						&.active {
							width: auto;

							.bp3-icon {
								margin-top: 0;
							}
						}
					}

					ul {
						display: flex;
						flex-wrap: wrap;

						li {
							flex-grow: 0;
						}

						ul {
							display: block;
						}
					}

					li {
						width: 20%;
						display: block;

						li {
							width: auto;
						}
					}

					li.overview {
						background-color: #626295;
						position: absolute;
						top: 20px;
						left: 20px;
						width: 200px;

						a {
							color: #ffffff;


							&:hover {
								background-color: #7373af;
							}
						}
					}
				}

				&:last-child .submenu {
					right: auto;
					left: 0;
				}
			}
		}
	}

	#page-content {
		padding: 5px;
	}
}

#page-content
{
	min-height: 800px;
	height: 1px;
	padding: 20px 10px 0;
	flex-grow: 1;

	#page-panel {
		box-shadow: 0 0 9px rgba(0, 0, 0, 0.01);
		border-radius: 10px;
		height: 100%;

		#top-actions
		{
			float: right;

			.bp3-button {
				margin: 0 5px;
			}
			.bp3-button-text {
				margin-top: -4px;
			}

			@media print {
				display: none;
			}
		}
	}
}

.user-menu
{
	margin-top: 10px;
	cursor: pointer;

	img {
		width: 60px;
		height: 60px;
		border: 5px solid #21bdca;
		border-radius: 50%;
		margin-left: 5px;
		float: right;
	}

	h3 {
		padding-top: 10px;
		margin: 0;
		color: #21bdca;
	}
	h5 {
		margin: 0;
	}
}

#page-404 {
	font-size: 100px;
	color: #7b8288;
	text-align: center;
	margin-top: 100px;

	span {
		margin: 0 auto;
	}
}

#login
{
	width: 400px;
	max-width: 99%;
	margin: 100px auto;

	img	{
		margin: 10px auto;
		display: block;
	}

	label {
		margin-bottom: 5px;
		font-family: "Bold", serif;
		display: block;
	}

	input {
		margin-bottom: 15px;
	}
}

#portal
{
	.bp3-button.bp3-intent-primary {
		background-color: #a3a3c7;
		box-shadow: none;
		transition: background-color 0.5s;
	}
	.bp3-button.bp3-intent-primary:hover {
		background-color: #8f8faf;
	}
	.bp3-button.bp3-intent-primary .bp3-icon {
		height: 19px;
		padding-top: 1px;
		border-right: 1px solid #7a7a94;
		padding-right: 5px;
	}

	.bp3-breadcrumb, .bp3-breadcrumb-current, .bp3-breadcrumbs-collapsed {
		font-size: 20px;
	}
	.bp3-breadcrumb, .bp3-breadcrumbs-collapsed {
		color: #9696bc;
	}
	.bp3-breadcrumbs {
		height: auto;
		padding: 0 0 15px 0;
	}
	.bp3-breadcrumbs > li {
		display: flex;
		align-items: flex-end;
	}

	@media print {
		.bp3-breadcrumbs,
		.bp3-breadcrumbs + .bp3-divider {
			display: none;
		}
	}
}

@keyframes drawLogo {
	to {
		stroke-dashoffset: 0;
	}
}
@keyframes hideDrawLogo {
	to {
		opacity: 0;
	}
}

@keyframes showColoredLogo {
	to {
		opacity: 1;
	}
}

#portal-loading
{
	background-color: #3150be;
	height: 100%;
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.logo
	{
		position: relative;
		width: 200px;
		height: 200px;

		* {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		svg {
			width: 100%;
			margin: 0 auto;
			display: block;
			stroke-dasharray: 3500px;
			stroke-dashoffset: 3500px;
			animation: drawLogo 2s linear forwards alternate, hideDrawLogo 0.5s linear 2.1s forwards;

			path {
				stroke: rgb(255, 255, 255);
				fill: none;
			}
		}

		img {
			width: 100%;
			opacity: 0;
			animation: showColoredLogo 1s linear 2s forwards alternate;
		}
	}

	.bp3-callout {
		max-width: 490px;
		text-align: center;
		margin: 0 auto;
	}
}

#notifications-menu
{
	background-color: #24cbdb;
	color: #ffffff;
	width: 200px;

	.bp3-menu-item {
		padding: 10px 5px;

		&:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}
	}
	.bp3-menu-divider {
		border-color: rgba(255, 255, 255, 0.4);
		margin: 0;
	}

	a {
		cursor: default;
	}

	.bp3-text-overflow-ellipsis.bp3-fill {
		text-overflow: unset;
		white-space: normal;
	}
}

/**
 * Dark Mode
 **/
.bp3-dark
{
	#sidebar {
		background-color: #30404d;

		#apps-list {
			background-color: #0f1c52;

			li.active:before {
				background-color: #30404d;
			}
		}
	}
}

/**
 * RTL
 **/
.dir-rtl
{
	direction: rtl;

	#navbar
	{
		#logo {
			margin-left: auto;
		}

		.branch-selector {
			margin: 12px 70px 0 0;
			float: right;
		}

		#global-search
		{
			span.bp3-icon {
				left: auto;
				right: 0;
			}

			.bp3-button {
				right: auto;
				left: 0;

				&[class*="bp3-icon-"]::before {
					color: #ffffff;
				}
			}

			.bp3-navbar-divider {
				height: 50px;
			}
		}

		.bp3-navbar-group {
			float: left;
		}
	}

	#sidebar
	{
		#apps-list
		{
			float: right;

			> li > a {
				border-radius: 0 6px 6px 0;
				margin-right: 10px;

				i {
					margin-right: -10px;
				}
			}

			.submenu
			{

				ul a.active {
					padding-right: 25px;
					padding-left: 5px;

					.bp3-icon {
						float: left;
						transform: scale(1.5);
					}
				}
			}
		}
	}

	#page-content #page-panel #top-actions {
		float: left;
	}

	#portal
	{
		.bp3-callout.bp3-callout-icon
		{
			padding: 10px 40px 9px 12px;
			margin-bottom: 10px;

			> .bp3-icon:first-child {
				left: auto;
				right: 10px;
			}
		}
		.bp3-input-group .bp3-input-action:first-child, .bp3-input-group > .bp3-button:first-child, .bp3-input-group > .bp3-icon:first-child {
			left: auto;
			right: 0;
		}
		.bp3-input-group .bp3-input:not(:first-child) {
			padding-left: 0;
			padding-right: 30px !important;
		}
		.bp3-button-group .bp3-button:first-child {
			border-radius: 0 5px 5px 0 !important;
		}
		.bp3-button-group .bp3-button:last-child {
			border-radius: 5px 0 0 5px !important;
		}
		.bp3-button::before, .bp3-button > * {
			margin-right: 0;
			margin-left: 7px;
		}
		.bp3-breadcrumbs > li::after {
			background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.41 8l3.29-3.29c.19-.18.3-.43.3-.71a1.003 1.003 0 00-1.71-.71l-4 4C5.11 7.47 5 7.72 5 8c0 .28.11.53.29.71l4 4a1.003 1.003 0 001.42-1.42L7.41 8z' fill='%235C7080'/%3e%3c/svg%3e");
		}
		.bp3-tab-list > *:not(:last-child) {
			margin: 0 0 0 20px;
		}
		.bp3-tab-indicator-wrapper {
			margin: 0 !important;
		}
		.bp3-button.bp3-intent-primary .bp3-icon {
			border-left: 1px solid #7a7a94;
			border-right: 0;
			padding-left: 5px;
			padding-right: 0;
		}

	}

	.bp3-menu {
		text-align: right;

		h6 {
			margin: 6px;
		}
	}
	.bp3-menu-item::before, .bp3-menu-item > * {
		margin-left: 7px;
		margin-right: 0;
	}
}

.DayPicker, .bp3-popover .bp3-popover-arrow {
	direction: ltr;
}

.bp3-transition-container {
	z-index: 9999;
}

.bp3-portal {
	z-index: 10000;
}

@font-face {
	font-family: 'Glyphter';
	src: url('fonts/Glyphter.eot');
	src: url('fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
	url('fonts/Glyphter.woff') format('woff'),
	url('fonts/Glyphter.ttf') format('truetype'),
	url('fonts/Glyphter.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
}
.apps-icons:before{
	display: inline-block;
	font-family: 'Glyphter';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 25px;
}
.icon-menu-01:before{content:'\0041';}
.icon-menu-02:before{content:'\0042';}
.icon-menu-03:before{content:'\0043';}
.icon-menu-04:before{content:'\0044';}
.icon-menu-05:before{content:'\0045';}
.icon-menu-06:before{content:'\0046';}
.icon-menu-07:before{content:'\0047';}
.icon-menu-08:before{content:'\0048';}
.icon-menu-09:before{content:'\0049';}
.icon-menu-10:before{content:'\004a';}
.icon-menu-11:before{content:'\004b';}
.icon-menu-12:before{content:'\004c';}
.icon-menu-13:before{content:'\004d';}
.icon-menu-14:before{content:'\004e';}



// kabak
@media (max-width: 768px) {
	body {
		color: #11bbb4 !important;
	}

	.bp3-dialog {
		max-width: 80% !important;
		background-color: #fff !important;
	}

	#portal
	{
		padding-top: 0;

		.container-fluid {
			margin: 0;
			padding: 0;

			.row {
				margin: 0;
			}
		}

		.bp3-button.bp3-intent-primary {
			background-color: #11bbb4;
		}

		#top-actions {
			float: none !important;
			text-align: center;
		}

		#crud-form h3, #crud-form .id-field {
			color: #11bbb4;
		}

		#crud-form textarea, #crud-form .css-yk16xz-control, #crud-form .bp3-popover-wrapper input, #crud-form .bp3-input-group.bp3-intent-primary .bp3-input {
			border-color: #11bbb4;
		}

		#crud-form {
			padding-bottom: 20px;
		}

		#crud-show {
			padding-bottom: 40px;

			h1 {
				background-color: #11bbb4;
				color: #ffffff;
			}

			.field h4 {
				color: #666666;
			}

			.media-field {
				h4 {
					display: none;
				}
			}

			.description-field h4 {
				min-width: 0;
			}
		}

		#listing-data {
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			> div {
				width: 50%;
			}
		}

		.products-crud-list {
			#listing-data > div {
				margin: 1%;
				width: 48%;
			}

			.more-actions button {
				margin: 3px;
				text-align: center;

				span {
					margin: 0;
				}

				.bp3-button-text {
					display: none;
				}
			}
		}

		.list-row {
			display: block;
			min-height: 200px;
			color: #555555;

			.price-field, .brand-field, .views-field, .orders-field, .stars-field {
				display: none;
			}

			.field {
				max-width: none;
				padding: 5px;

				h4 {
					margin: 0;
					display: block;
				}

				&.status-field span {
					padding: 3px 5px !important;
					display: inline-block;
					border-radius: 5px;
				}

				&.name-field h4, &.thumbnail-field h4 {
					display: none;
				}

				img {
					width: 100px !important;
					height: auto;
					margin: 0 !important;
					display: inline !important;
				}
			}

			.bp3-button {
				background-color: #11bbb4;
				color: #ffffff;

				&.bp3-intent-danger {
					background-color: #cb3535;
				}

				.bp3-icon {
					color: #ffffff;
				}
			}
		}
		.more-actions {
			width: 100%;
		}

		#wrapper
		{
			#sidebar {
				background-color: transparent;
				position: static;
				opacity: 1;

				.close {
					display: none;
				}

				#apps-list {
					display: block;

					> li {
						display: none;
					}

					#app-commerce {
						display: block;

						> a, h3 {
							display: none;
						}

						.submenu
						{
							padding: 5px;
							display: block;
							position: static;

							li {
								width: auto;
								text-align: center;
							}

							ul {
								border-bottom: 1px solid #dddddd;
								justify-content: space-between;

								li {
									flex-grow: 1;

									a {
										text-align: center;
										padding: 10px 0 !important;
										
										&.active {
											background-color: #11bbb4;
										}
									}
								}
							}

							svg {
								display: none;
							}
						}
					}
				}
			}
		}

		#navbar, #filters, .bp3-breadcrumbs, .bp3-breadcrumbs + .bp3-divider, .list-headline {
			display: none;
		}

		#top-actions {
			.export, .print {
				display: none;
			}
		}

		#page-panel {
			padding: 5px;
		}
	}

	#login-page {
		background-color: #ffffff;

		#login-box
		{
			h1 {
				display: block;
				text-align: center;
				font-size: 20px;
				margin-bottom: 25px;
			}

			.field {
				input {
					background-color: #fff;
					border: 1px solid #000000;
					border-radius: 50px;
					color: #000000;

					&::placeholder {
						color: gray;
					}
				}

				.bp3-icon {
					color: gray;
				}
			}

			button {
				background-color: #11bbb4;
				margin: 30px auto 0;
				float: none;
				display: block;
				border-radius: 50px;
			}
		}
	}
}

