#accounting-sales-invoice
{
	.row {
		margin-bottom: 15px;
	}

	table {
		td, th {
			text-align: right;
		}
	}
}